<template>
  <div id="queue-paper" style="width: 100%; height: 100%;">
    <h4
      style="
        text-align: center;
        font-size: 70px;
        font-family: 'GE SS Two' !important;
      "
    >
      الأمانة السورية للتنمية
    </h4>
    <h4
      style="
        text-align: center;
        font-size: 70px;
        font-family: 'GE SS Two' !important;
      "
    >
      منارة حلب القديمة
    </h4>
    <h1
      style="
        font-size: 240px;
        margin-top: 250px;
        margin-bottom: 250px;
        font-weight: bold;
        text-align: center;
      "
    >
      {{
        (visitor_id >= 100 ? "0" : visitor_id >= 10 ? "00" : "000") +
          visitor_id || "230"
      }}
    </h1>
    <h1
      style="
        font-size: 30px;
        margin-bottom: 75px;
        font-weight: bold;
        text-align: center;
        font-family: 'GE SS Two' !important;
      "
    >
      {{ title }}
    </h1>
    <h1
      style="
        font-size: 50px;
        margin-bottom: 75px;
        font-weight: bold;
        text-align: center;
        font-family: 'GE SS Two' !important;
      "
    >
      النافذة الواحدة
    </h1>
    <div style="display: flex; justify-content: space-between; width: 100%">
      <h1 style="font-size: 50px">
        <span style="font-family: 'GE SS Two' !important"
          >{{ day }}
        </span>
        {{ date }}
      </h1>
      <h1 style="font-size: 50px">
        <span style="font-family: 'GE SS Two' !important"
          >التوقيت
        </span>
        {{ time }}
      </h1>
    </div>
  </div>
</template>
<script>
import { title } from "@/@core/utils/filter";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("publicPages/Queue", [
      "visitor_id",
      "date",
      "day",
      "time",
      "title",
    ]),
  },
};
</script>
