<template>
  <div>

    <b-overlay id="overlay-background" :show="loadingAddToQueue" variant="white" opacity="0.7" blur="2px" rounded="lg"
      style="width: 100vw; height: 100vh">
      <b-container fluid="md" class="services-page" style="width: 100vw; height: 100v">
        <div>
          <vuexy-logo style="width: 100px; height: 100px" class="mx-4 mt-4" />
        </div>
        <h1 class="fs-big text-center">المديرية العامة للآثار والمتاحف</h1>

        <b-row v-if="loading">
          <b-col v-for="n in 5" :key="n" cols="3" md="3" sm="4" lg="3" xl="2">
            <b-card>
              <b-skeleton height="200px"></b-skeleton>
            </b-card>
          </b-col>
        </b-row>

        <b-row v-else class="mt-3 h-30">
          <b-col v-for="(item, id) in procedureQ" :key="id" cols="3" md="3" sm="4" lg="3" xl="2" class="h-100">
            <div @click="addQueue(item.id);setTitle(item.title)">
              <card-services :item="item"></card-services>
            </div>
          </b-col>
        </b-row>
        <h1 class="text-center title-services">مركز خدمة الموطن</h1>
        <b-row v-if="loading">
          <b-col v-for="n in 5" :key="n" cols="3" md="3" sm="4" lg="3" xl="2">
            <b-card>
              <b-skeleton height="100px"></b-skeleton>
            </b-card>
          </b-col>
        </b-row>
        <b-row v-else class="mt-3 h-30">
          <b-col v-for="(item, id) in servicesQ" :key="id" cols="2">
            <b-button variant="primary" class="w-100" style="height: 100px" @click="addQueue(item.id);setTitle(item.title)">
              <h1 class="text-center text-weight d-flex align-items-center justify-content-center h-100">
                {{ item.title }}
              </h1>
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-overlay>
    <queue-paper style="display: none;" />
  </div>
</template>
<script>
import {
  BContainer,
  BRow,
  BCol,
  BImg,
  BCard,
  BButton,
  BSkeleton,
  BOverlay,
} from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import CardServices from "../../components/CaedServices.vue";
import { mapActions, mapGetters } from "vuex";
import { debounce } from "lodash";
import QueuePaper from "../../components/QueuePaper.vue";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BImg,
    VuexyLogo,
    CardServices,
    BCard,
    BButton,
    BSkeleton,
    BOverlay,
    QueuePaper
  },
  data: () => {
    return {

    };
  },
  methods: {
    ...mapActions("publicPages/Queue", ["getServices", "getProcedure", "addToQueue", "setTitle"]),
    addQueue: debounce(function (id) {
      this.addToQueue({
        data: {
          service_id: id,
          is_by_queue: true,
        },
      }).then(() => {
        this.$htmlToPaper('queue-paper');
      })
    }, 400),
  },
  computed: {
    ...mapGetters("publicPages/Queue", [
      "loading",
      "servicesQ",
      "procedureQ",
      "loadingAddToQueue",
      "loadingAddToQueue",
    ]),
  },
  created() {
    this.getServices();
    this.getProcedure();
  },
};
</script>

<style lang="scss">
.services-page {
  .title-services {
    font-weight: 400;
    font-size: 40px;
    line-height: 50px;
    color: #333;
  }

  .ok-btn {
    height: 78.49px;
    background: #d0a153;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
  }

  .number-style {
    font-family: "Cairo";
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 94px;
    color: #ffffff;
  }

  .number-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 78.49px;
    background: #d0a153;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    border-radius: 10.9515px;
  }

  .fs-big-number {
    height: 94px;
    font-weight: 400;
    font-size: 50px;
    line-height: 94px;
    text-align: center;
    color: #000000;
  }

  .fs-big {
    /* ما مدى رضاك عن الخدمات المقدمة في المنارة */
    position: absolute;
    width: 100%;
    top: 50px;
    font-weight: 400;
    font-size: 40px;
    line-height: 75px;
    color: #333;
  }

  .number-print {
    height: 100px;
    background: #ededed;
    border-radius: 10px;
  }
}
</style>
